import {
	config,
	EditablePage,
	EnvironmentContextProvider,
	ProvideAll,
} from '@ds-mgnl/marketing';
import { useLoaderData } from 'react-router-dom';
import type { LoaderFunction } from 'react-router-dom';
import { getMagnoliaBaseDamUrl, getMagnoliaBaseUrl } from '../util';

export const magnoliaPageLoader: LoaderFunction = async ({ request }) => {
	const currentPageUrl = new URL(request.url);
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath = new URL(
		`${magnoliaBaseUrl}${process.env.MGNL_API_PAGES!}${
			process.env.MGNL_PAGES_APP_BASE
		}${currentPageUrl.pathname}`
	);

	// Add the x-mgnl-tels-referral-traits request paramater to the API request.
	// Enables personalization using the 'requestParameterTrait' trait type. https://docs.magnolia-cms.com/product-docs/6.3/developing/headless/spa-development/personalization-of-headless-spa-projects/#_configure_one_or_more_traits
	const searchParams = currentPageUrl.searchParams;
	const hasReferralParam = searchParams.has('referral');
	if (hasReferralParam) {
		const telsReferralHeader = searchParams.get('referral');
		if (telsReferralHeader) {
			fullContentPath.searchParams.set(
				'x-mgnl-tels-referral-traits',
				telsReferralHeader
			);
		}
	}

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const assetCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/collateral-library/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const resourceCategoryPageLoader: LoaderFunction = async ({
	params,
}) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/resources/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const insightCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/insights/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export const legalCategoryPageLoader: LoaderFunction = async ({ params }) => {
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const fullContentPath =
		magnoliaBaseUrl +
		process.env.MGNL_API_PAGES! +
		process.env.MGNL_PAGES_APP_BASE +
		'/legal/' +
		params['*'];

	const pageResponse = await fetch(fullContentPath);
	if (pageResponse.status != 200) {
		throw new Error(pageResponse.statusText);
	}
	const pageJson = await pageResponse.json();
	return { pageJson };
};

export function MagnoliaPage() {
	const { pageJson } = useLoaderData() as any;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const magnoliaBaseDamUrl = getMagnoliaBaseDamUrl();

	return (
		<ProvideAll>
			<EnvironmentContextProvider
				value={{
					damHost: magnoliaBaseDamUrl!,
					appHost: magnoliaBaseUrl!,
					isInMagnolia: false,
				}}
			/>
			<EditablePage
				templateAnnotations={{}}
				content={pageJson}
				config={config}
			></EditablePage>
		</ProvideAll>
	);
}
